<script setup lang="ts">
import type { DbStock } from '~~/types/stock.types';
import { BanknotesIcon } from '@heroicons/vue/16/solid';

const props = withDefaults(defineProps<{
  stock: DbStock | { ticker_symbol: string, name: string, logo_url?: string }
  showLogo?: boolean
  hideNameInMobile?: boolean
  isHigh?: boolean
  showDividendIcon?: boolean
  showTickerSymbol?: boolean
  showName?: boolean
  showExchange?: boolean
  hasLink?: boolean
}>(), {
  showLogo: true,
  hideNameInMobile: false,
  isHigh: false,
  showDividendIcon: false,
  showTickerSymbol: true,
  showName: true,
  showExchange: true,
  hasLink: false
})

// Separating the exchange and the ticker symbol
const tickerSymbol = computed<string>(() => {
  if (props.stock.ticker_symbol?.includes(':')) {
    const [, tickerSymbol] = props.stock.ticker_symbol.split(':')
    return tickerSymbol
  } else {
    return props.stock.ticker_symbol
  }
})
const exchange = computed<string | null>(() => {
  if (props.stock.ticker_symbol?.includes(':')) {
    const [exchange] = props.stock.ticker_symbol.split(':')
    return exchange
  } else {
    return null
  }
})

const wordsToDelete = [
  "Inc",
  "Corp",
  "Corp.",
  "Corporation",
  "Incorporation",
  "Incorporated",
  "Company",
  "p.l.c.",
  "Ltd",
  "Limited",
  "LLC",
  "AG",
  "Oyj",
  "PJSC",
  "PLC",
  "SA",
  "NV"
]

// Function to remove the words from the name
function removeWordsFromName(name: string) {
  if (!name) return name
  const words = name.split(' ')
  const newWords = words.filter(word => !wordsToDelete.includes(word) && !wordsToDelete.includes(word.replace('.', '')))
  return newWords.join(' ')
}
</script>

<template>
  <div class="overflow-hidden">
    <Tooltip class="inline-block" v-if="hasLink">
      <template #reference>
        <NuxtLink :to="`/company/${stock.ticker_symbol}`" class="flex items-start gap-y-0 flex-row sm:items-center group">
          <img v-if="showLogo && 'eodhd_logo' in stock && stock.eodhd_logo" class="w-6 h-auto max-h-8 my-auto mr-3" :src="`https://eodhistoricaldata.com${stock.eodhd_logo}`" :alt="stock.ticker_symbol" /> 
          <img v-else-if="showLogo && 'logo_url' in stock && stock.logo_url" class="w-6 h-auto max-h-8 my-auto mr-3" :src="stock.logo_url" :alt="stock.ticker_symbol" />
          <div v-else class="w-0 h-6"></div>
          <p class="overflow-hidden text-ellipsis whitespace-nowrap">
            <template v-if="stock.name">
              <span v-if="showName" class="group-hover:underline" :class="hideNameInMobile ? 'hidden sm:inline md:hidden lg:inline': ''" :title="stock.name">
                {{ removeWordsFromName(stock.name) ?? '' }}
              </span>
              <span :class="[hideNameInMobile ? 'sm:text-xs sm:opacity-50' : 'text-xs opacity-50', isHigh ? 'flex mt-1' : 'ml-2 mt-2', 'items-center']">
                <span v-if="exchange && showExchange" class="hidden sm:inline">{{ exchange }}:</span>
                <span v-if="showTickerSymbol">{{ tickerSymbol }}</span>
                <BanknotesIcon v-if="showDividendIcon" class="inline-block h-3 w-3 ml-1 mt-0.5 opacity-50 sm:opacity-100" />
              </span>
            </template>
            <span v-else class="flex items-center group-hover:underline">
              <span v-if="exchange && showExchange" class="hidden sm:inline">{{ exchange }}:</span>
              <span v-if="showTickerSymbol">{{ tickerSymbol }}</span>
              <BanknotesIcon v-if="showDividendIcon" class="inline-block h-3 w-3 ml-1 mt-0.5 opacity-50" />
            </span>
          </p>
        </NuxtLink>
      </template>
      <template #floating>
        Voir la valorisation de l'entreprise
      </template>
    </Tooltip>

    <div v-else class="flex items-start gap-y-0 flex-row sm:items-center">
      <img v-if="showLogo && 'eodhd_logo' in stock && stock.eodhd_logo" class="w-6 h-auto max-h-8 my-auto mr-3" :src="`https://eodhistoricaldata.com${stock.eodhd_logo}`" :alt="stock.ticker_symbol" /> 
      <img v-else-if="showLogo && 'logo_url' in stock && stock.logo_url" class="w-6 h-auto max-h-8 my-auto mr-3" :src="stock.logo_url" :alt="stock.ticker_symbol" />
      <div v-else class="w-0 h-6"></div>
      <p class="overflow-hidden text-ellipsis whitespace-nowrap">
        <template v-if="stock.name">
          <span v-if="showName" :class="hideNameInMobile ? 'hidden sm:inline md:hidden lg:inline': ''" :title="stock.name">
            {{ removeWordsFromName(stock.name) ?? '' }}
          </span>
          <span :class="[hideNameInMobile ? 'sm:text-xs sm:opacity-50' : 'text-xs opacity-50', isHigh ? 'flex mt-1' : 'ml-2 mt-2', 'items-center']">
            <span v-if="exchange && showExchange" class="hidden sm:inline">{{ exchange }}:</span>
            <span v-if="showTickerSymbol">{{ tickerSymbol }}</span>
            <BanknotesIcon v-if="showDividendIcon" class="inline-block h-3 w-3 ml-1 mt-0.5 opacity-50 sm:opacity-100" />
          </span>
        </template>
        <span v-else class="flex items-center">
          <span v-if="exchange && showExchange" class="hidden sm:inline">{{ exchange }}:</span>
          <span v-if="showTickerSymbol">{{ tickerSymbol }}</span>
          <BanknotesIcon v-if="showDividendIcon" class="inline-block h-3 w-3 ml-1 mt-0.5 opacity-50" />
        </span>
      </p>
    </div>
  </div>
</template>